import { Button, Grid, styled, ThemeProvider, ToggleButton, Typography, useTheme } from "@mui/material";
import ResponseData, { StatusResponseData } from "../components/Data/ResponseData";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import InputDataTextField from "../components/Data/InputDataTextField";
import InputData from "../components/Data/InputData";
import { ReactComponent as InterviewIcon } from "../assets/InterviewIcon.svg";
import { ReactComponent as VideoInterviewIcon } from "../assets/VideoInterviewIcon.svg";
import instance from "../services/axiosConfig";
import { VisuallyHiddenInput } from "../common/styled";
import { ReactComponent as AttachIcon } from "../assets/AttachIcon.svg";

const CustomToggleButton = styled(ToggleButton)(() => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: "#007FFF",
    },
}));

enum ConclusionType {
    General,
    Subjective,
    Weaknesses,
}

const ConclusionTypeString = ["Общее", "Субъективный вывод", "Слабые стороны"];

export default function Conclusion() {
    const theme = useTheme();
    const [selectedTypes, setSelectedTypes] = useState<ConclusionType[]>([ConclusionType.General]);
    const [phoneInterviewLink, setPhoneInterviewLink] = useState<string>("");
    const [videoInterviewLink, setVideoInterviewLink] = useState<string>("");
    const [profileFile, setProfileFile] = useState<File>();

    const [statusConclusion, setStatusConclusion] = useState<StatusResponseData>(StatusResponseData.idle);
    const [dataConclusion, setDataConclusion] = useState<string>("");

    const { enqueueSnackbar } = useSnackbar();

    const inputItems = [
        {
            label: "Ссылка на телефонное интервью",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setPhoneInterviewLink(event.target.value),
            placeholder: "Введите ccылку",
            id: "phoneInterviewLink",
            type: "",
            icon: <InterviewIcon />,
            required: true,
            value: phoneInterviewLink,
            isFile: false,
        },
        {
            label: "Ссылка на видео-интервью",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setVideoInterviewLink(event.target.value),
            placeholder: "Введите ссылку",
            id: "videoInterviewLink",
            type: "",
            icon: <VideoInterviewIcon />,
            required: true,
            value: videoInterviewLink,
            isFile: false,
        },
        {
            label: "Файл профиля должности",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleUploadProfileFile(event),
            placeholder: "Выберите файл",
            id: "profileFile",
            type: "",
            icon: <AttachIcon />,
            required: true,
            value: profileFile,
            isFile: true,
        },
    ];

    const handleConclusionPost = () => {
        if (!profileFile) return;
        setStatusConclusion(StatusResponseData.waitingForResponse);

        const formData = new FormData();
        formData.append("calling_url", phoneInterviewLink);
        formData.append("video_interview_url", videoInterviewLink);
        formData.append("profile_file", profileFile);
        formData.append("requests", JSON.stringify(selectedTypes));

        instance
            .post("/api/conclusion/sdm", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                enqueueSnackbar("Заключение сформировано успешно!", {
                    variant: "success",
                });
                setDataConclusion(res.data);
                setStatusConclusion(StatusResponseData.dataReceived);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
                setStatusConclusion(StatusResponseData.responseError);
            });
    };

    const handleClean = () => {
        setDataConclusion("");
        setStatusConclusion(StatusResponseData.idle);
        setSelectedTypes([ConclusionType.General]);

        setPhoneInterviewLink("");
        setVideoInterviewLink("");
        setProfileFile(undefined);
    };

    const handleUploadProfileFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setProfileFile(e.target.files[0]);
            enqueueSnackbar("Файл загружен", { variant: "info" });
        } else {
            enqueueSnackbar("Ошибка при загрузке файла! Попробуйте ещё раз либо свяжитесь сообщите нам об ошибке.", {
                variant: "error",
            });
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="space-between" style={{ marginBottom: 16 }}>
                <Typography variant="h3" style={{ color: "#5D6570" }}>
                    Заключение по кандидату МОП
                </Typography>

                <Button
                    variant="contained"
                    style={{ marginRight: 0 }}
                    onClick={handleConclusionPost}
                    disabled={
                        inputItems.filter((item) => item.required).some((item) => !item.value) ||
                        selectedTypes.length === 0
                    }
                >
                    Сформировать
                </Button>
            </Grid>

            <Grid style={{ marginBottom: 16 }}>
                {ConclusionTypeString.map((v, i) => {
                    return (
                        <CustomToggleButton
                            size="small"
                            style={{ borderRadius: 12, marginLeft: 0, padding: "4px 8px 4px 8px" }}
                            value="v"
                            selected={selectedTypes.includes(i)}
                            onChange={() => {
                                if (selectedTypes.includes(i)) {
                                    setSelectedTypes((ss) => ss.filter((s) => s !== i));
                                } else {
                                    setSelectedTypes((ss) => [...ss, i]);
                                }
                            }}
                        >
                            {v}
                        </CustomToggleButton>
                    );
                })}
            </Grid>

            <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}
                style={{ marginBottom: 32 }}
            >
                {inputItems.map((item) => (
                    <Grid item xs={2} sm={4} md={4} lg={4}>
                        <InputData
                            disableRipple={true}
                            label={item.label}
                            inputComponent={
                                !item.isFile && (typeof item.value === "string" || typeof item.value === "number") ? (
                                    <InputDataTextField
                                        value={item.value}
                                        onChange={item.onChange}
                                        placeholder={item.placeholder}
                                        id={item.id}
                                        type={item.type}
                                        resized={true}
                                    />
                                ) : (
                                    <Typography
                                        fontSize="12px"
                                        lineHeight="16px"
                                        fontWeight="bold"
                                        style={{
                                            color: "#949698",
                                            textTransform: "none",
                                            paddingBottom: "13px",
                                        }}
                                    >
                                        {item.value ? (item.value as File).name : item.placeholder}
                                        <VisuallyHiddenInput type="file" onChange={item.onChange} />
                                    </Typography>
                                )
                            }
                            inputIcon={item.icon}
                            resized={true}
                        />
                    </Grid>
                ))}
            </Grid>

            <ResponseData
                status={statusConclusion}
                data={dataConclusion}
                handleClean={handleClean}
                actionName={"Формирование заключения"}
                actionNameEn={"Get conclusion"}
            />
        </ThemeProvider>
    );
}
