import {
    AppBar,
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import { Link, useLocation } from "react-router-dom";
import authService from "../services/authService";
import { useSnackbar } from "notistack";
import instance from "../services/axiosConfig";
import { User, UserRole } from "../common/types";
import { useAuth } from "../context/AuthProvider";

export default function AppBarMain() {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [master, setMaster] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { setToken } = useAuth();
    const location = useLocation();

    const settings = [
        {
            name: "Статистика",
            url: "/statistics",
            required: false,
            active: location.pathname === "/statistics",
        },
        {
            name: "Настройки",
            url: "/settings",
            required: false,
            active: location.pathname === "/settings",
        },
        {
            name: "Группа",
            url: "/group",
            required: true,
            active: location.pathname === "/group",
        },
        {
            name: "История",
            url: "/history",
            required: false,
            active: location.pathname === "/history",
        },
    ];

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        instance
            .get<User>("api/user", {
                headers: {
                    Accept: "*/*",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                if (res.data.role !== UserRole.Slave) {
                    setMaster(true);
                }
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    }, [enqueueSnackbar]);

    const handleLogout = () => {
        authService.logout();
        setToken("");
        window.location.reload();
        enqueueSnackbar("Выход из системы успешен!", { variant: "success" });
    };

    return (
        <AppBar sx={{ boxShadow: "none" }}>
            <Toolbar
                sx={{
                    backgroundColor: "#fff",
                    boxShadow: "inset 0 -1px 0 rgba(0, 0, 0, 0.12)",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Logo />
                    <Typography
                        variant="body1"
                        style={{
                            color: "#5D6570",
                            marginLeft: "16px",
                            fontWeight: 500,
                        }}
                    >
                        HR ASSISTANT
                    </Typography>
                </Box>
                <List style={{ display: "flex", flexDirection: "row", padding: "0" }}>
                    {settings.map((setting) =>
                        setting.required ? (
                            master ? (
                                <ListItem
                                    key={setting.name}
                                    disablePadding
                                    style={{ backgroundColor: setting.active ? "#F0F7FF" : "", borderRadius: 8 }}
                                >
                                    <ListItemButton sx={{ textAlign: "center" }}>
                                        <Link to={setting.url} style={{ textDecoration: "none" }}>
                                            <Typography
                                                textAlign="center"
                                                variant="body1"
                                                fontWeight={setting.active ? "bold" : 500}
                                                color={setting.active ? "#0B0D0E" : "#5D6570"}
                                            >
                                                {setting.name}
                                            </Typography>
                                        </Link>
                                    </ListItemButton>
                                </ListItem>
                            ) : (
                                <></>
                            )
                        ) : (
                            <ListItem
                                key={setting.name}
                                disablePadding
                                style={{ backgroundColor: setting.active ? "#F0F7FF" : "", borderRadius: 8 }}
                            >
                                <ListItemButton sx={{ textAlign: "center" }}>
                                    <Link to={setting.url} style={{ textDecoration: "none" }}>
                                        <Typography
                                            textAlign="center"
                                            variant="body1"
                                            fontWeight={setting.active ? "bold" : 500}
                                            color={setting.active ? "#0B0D0E" : "#5D6570"}
                                        >
                                            {setting.name}
                                        </Typography>
                                    </Link>
                                </ListItemButton>
                            </ListItem>
                        )
                    )}
                    <ListItem key={"Выйти"} disablePadding onClick={handleCloseUserMenu}>
                        <ListItemButton sx={{ textAlign: "center" }}>
                            <Typography
                                textAlign="center"
                                variant="body1"
                                style={{ color: "#5D6570" }}
                                onClick={handleLogout}
                            >
                                Выйти
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Toolbar>
        </AppBar>
    );
}
