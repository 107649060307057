export enum UserRole {
    Creator,
    SuperAdmin,
    Master,
    Slave,
}

export interface User {
    id: string;
    username: string;
    active: boolean;
    role: UserRole;
    patron: string;
    group: UserGroup;
    group_id: string;
    created_at: number;
}

export interface UserSimple {
    username: string;
    active: boolean;
    role: UserRole;
    created_at: number;
}

export interface UserGroup {
    id: string;
    name: string;
    owner: User;
    owner_id: string;
    number_members: number;
    created_at: number;
}

export interface GroupWithUsers {
    group: UserGroup;
    users: User[];
}

export interface HistoryTranscribation {
    id: number;
    input_type: string;
    path: string;
    transcribation: string;
    owner: User;
    owner_id: string;
    created_at: number;
}

export interface Template {
    id: string;
    name: string;
    data: string[];
    owner: User;
    owner_id: string;
    created_at: number;
}

export interface HistorySearch {
    id: number;
    amount: number;
    region: string;
    type: string;
    data: string;
    owner: User;
    owner_id: string;
    created_at: number;
}

export interface HistoryQuestions {
    id: number;
    type: number[];
    position: string;
    type_of_activity: string;
    duties: string;
    tasks: string;
    stop: string;
    additional: string;
    data: string;
    owner: User;
    owner_id: string;
    created_at: number;
}

export interface HistoryCall {
    id: number;
    path: string;
    transcribation: string;
    questions: string;
    answers: string;
    owner: User;
    owner_id: string;
    created_at: number;
}

export interface HistoryGroup {
    type: string;
    input: { [index: string]: string };
    output: { [index: string]: string };
    username: string;
    created_at: number;
}

export interface Transcribation {
    result: string;
    success: boolean;
}

export interface Audio {
    id: number;
    name: string;
    input_type: string;
    path: string;
    completed: boolean;
    transcribation: string;
    owner: User;
    owner_id: string;
    created_at: number;
}

export interface StatisticsCountData {
    name: string;
    amount: number;
}

export interface StatisticsTimelineData {
    name: string;
    time: number;
}
