import { Grid, styled, ToggleButton } from "@mui/material";
import { useContext } from "react";
import { IntervalName, intervalNames, SelectedIntervalContext } from "../../context/SelectedIntervalContext";
import { DateTime, Interval } from "luxon";

const CustomToggleButton = styled(ToggleButton)(() => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: "#007FFF",
    },
}));

export default function SelectDate() {
    const { changeInterval, intervalName, changeIntervalName } = useContext(SelectedIntervalContext);

    const getInterval = (name: IntervalName): Interval => {
        switch (name) {
            case "Сегодня": {
                return Interval.fromDateTimes(DateTime.now().startOf("day"), DateTime.now().endOf("day"));
            }
            case "Вчера": {
                return Interval.fromDateTimes(
                    DateTime.now().minus({ day: 1 }).startOf("day"),
                    DateTime.now().minus({ day: 1 }).endOf("day")
                );
            }
            case "За неделю": {
                return Interval.fromDateTimes(DateTime.now().startOf("week"), DateTime.now().endOf("week"));
            }
            case "За месяц": {
                return Interval.fromDateTimes(DateTime.now().startOf("month"), DateTime.now().endOf("month"));
            }
            case "За год": {
                return Interval.fromDateTimes(DateTime.now().startOf("year"), DateTime.now().endOf("year"));
            }
            case "Выбрать": {
                return Interval.fromDateTimes(DateTime.now().startOf("day"), DateTime.now().endOf("day"));
            }
        }
    };

    return (
        <Grid style={{ marginBottom: 16 }}>
            {intervalNames.map((v) => {
                return (
                    <CustomToggleButton
                        size="small"
                        style={{ borderRadius: 12, marginLeft: 0, padding: "4px 8px 4px 8px" }}
                        value="v"
                        selected={v === intervalName}
                        onChange={() => {
                            if (changeIntervalName !== undefined) changeIntervalName(v);
                            if (changeInterval !== undefined) changeInterval(getInterval(v));
                        }}
                    >
                        {v}
                    </CustomToggleButton>
                );
            })}
        </Grid>
    );
}
