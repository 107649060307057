import { List, Drawer } from "@mui/material";
import React, { useState } from "react";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useLocation } from "react-router-dom";
import { ReactComponent as SpeechToText } from "../../assets/SpeechToText.svg";
import { ReactComponent as SearchCompanyIcon } from "../../assets/SearchCompanyIcon.svg";
import { ReactComponent as SearchLanguageIcon } from "../../assets/SearchLanguageIcon.svg";
import { ReactComponent as QuestionIcon } from "../../assets/QuestionIcon.svg";
import { ReactComponent as TutorialIcon } from "../../assets/TutorialIcon.svg";
import { ReactComponent as InterviewIcon } from "../../assets/InterviewIcon.svg";
import { ReactComponent as VideoInterviewIcon } from "../../assets/VideoInterviewIcon.svg";
import { ReactComponent as StatisticsIcon } from "../../assets/StatisticsIcon.svg";
import { ReactComponent as ConclusionIcon } from "../../assets/ConclusionIcon.svg";
import { ReactComponent as ContactSupportIcon } from "../../assets/ContactSupportIcon.svg";
import NavigationMenuItem from "./NavigationMenuItem";

const fullNavMenuWidth = 300;
const collapsedNavMenuWidth = 70;

export default function NavigationMenu() {
    const [showFullNavMenu, setShowFullNavMenu] = useState<boolean>(false);
    const navMenuWidth = showFullNavMenu ? fullNavMenuWidth : collapsedNavMenuWidth;

    const location = useLocation();

    const drawerItems = [
        {
            icon: <InboxIcon fill={location.pathname === "/" ? "#007FFF" : "#5D6570"} style={{ marginRight: 8 }} />,
            text: "Главная",
            path: "/",
            position: "top",
        },
        {
            icon: (
                <SpeechToText
                    fill={location.pathname === "/transcribation" ? "#007FFF" : "#5D6570"}
                    style={{ marginRight: 8 }}
                />
            ),
            text: "Транскрибация",
            path: "/transcribation",
            position: "top",
        },
        {
            icon: (
                <InterviewIcon
                    fill={location.pathname === "/calling" ? "#007FFF" : "#5D6570"}
                    style={{ marginRight: 8 }}
                />
            ),
            text: "Телефонное интервью",
            path: "/calling",
            position: "top",
        },
        {
            icon: (
                <VideoInterviewIcon
                    fill={location.pathname === "/video-interview" ? "#007FFF" : "#5D6570"}
                    style={{ marginRight: 8 }}
                />
            ),
            text: "Видеоинтервью",
            path: "/video-interview",
            position: "top",
        },
        {
            icon: (
                <SearchCompanyIcon
                    fill={location.pathname === "/search-company" ? "#007FFF" : "#5D6570"}
                    style={{ marginRight: 8 }}
                />
            ),
            text: "Компании-доноры",
            path: "/search-company",
            position: "top",
        },
        {
            icon: (
                <SearchLanguageIcon
                    fill={location.pathname === "/search-language" ? "#007FFF" : "#5D6570"}
                    style={{ marginRight: 8 }}
                />
            ),
            text: "Язык поиска HH",
            path: "/search-language",
            position: "top",
        },
        {
            icon: (
                <QuestionIcon
                    fill={location.pathname === "/questions" ? "#007FFF" : "#5D6570"}
                    style={{ marginRight: 8 }}
                />
            ),
            text: "Формирование вопросов",
            path: "/questions",
            position: "top",
        },
        {
            icon: (
                <ConclusionIcon
                    fill={location.pathname === "/conclusion" ? "#007FFF" : "#5D6570"}
                    style={{ marginRight: 8 }}
                />
            ),
            text: "Заключение по кандидату",
            path: "/conclusion",
            position: "top",
        },
        {
            icon: (
                <StatisticsIcon
                    fill={location.pathname === "/statistics" ? "#007FFF" : "#5D6570"}
                    style={{ marginRight: 8 }}
                />
            ),
            text: "Статистика",
            path: "/statistics",
            position: "bottom",
        },
        {
            icon: (
                <TutorialIcon
                    fill={location.pathname === "/tutorial" ? "#007FFF" : "#5D6570"}
                    style={{ marginRight: 8 }}
                />
            ),
            text: "Руководство",
            path: "/tutorial",
            position: "bottom",
        },
        {
            icon: (
                <ContactSupportIcon
                    fill={location.pathname === "/support" ? "#007FFF" : "#5D6570"}
                    style={{ marginRight: 8 }}
                />
            ),
            text: "Связаться с поддержкой",
            path: "/support",
            position: "bottom",
        },
    ];

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: navMenuWidth,
                display: { xs: "none", sm: "block" },
                position: "absolute",
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: navMenuWidth,
                    marginTop: "64px",
                },
            }}
            onMouseEnter={() => setShowFullNavMenu(true)}
            onMouseLeave={() => setShowFullNavMenu(false)}
        >
            <List style={{ marginTop: "8px" }}>
                {drawerItems.map((item, i, index) =>
                    item.position === "top" ? (
                        <NavigationMenuItem
                            key={item.path}
                            text={showFullNavMenu ? item.text : ""}
                            path={item.path}
                            icon={item.icon}
                            isActive={location.pathname === item.path}
                        />
                    ) : (
                        <></>
                    )
                )}
            </List>
            <List style={{ marginTop: "auto", marginBottom: "64px" }}>
                {drawerItems.map((item, i, index) =>
                    item.position === "bottom" ? (
                        <NavigationMenuItem
                            key={item.path}
                            text={showFullNavMenu ? item.text : ""}
                            path={item.path}
                            icon={item.icon}
                            isActive={location.pathname === item.path}
                        />
                    ) : (
                        <></>
                    )
                )}
            </List>
        </Drawer>
    );
}
