import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { StatisticsCountData } from "../../common/types";
import { useContext, useEffect, useState } from "react";
import { SelectedIntervalContext } from "../../context/SelectedIntervalContext";
import instance from "../../services/axiosConfig";
import { useSnackbar } from "notistack";

export default function BarStatiscticsAmount() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState<StatisticsCountData[]>([]);
    const { interval, intervalName } = useContext(SelectedIntervalContext);

    useEffect(() => {
        if (interval.start === null || interval.end === null) return;
        instance
            .post<StatisticsCountData[]>(
                "api/statistics/amount",
                {
                    start: Math.round(interval.start.toUTC().valueOf() / 1000),
                    end: Math.round(interval.end.toUTC().valueOf() / 1000),
                },
                {
                    headers: {
                        Accept: "*/*",
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                setData(res.data);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    }, [enqueueSnackbar, interval]);

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart width={500} height={300} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} width={100} />
                <Tooltip />
                <Legend />
                <Bar dataKey="amount" fill="#007FFF" name="Количество" />
            </BarChart>
        </ResponsiveContainer>
    );
}
