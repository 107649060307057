import { Grid, ThemeProvider, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { IntervalName, SelectedIntervalContext } from "../context/SelectedIntervalContext";
import { DateTime, Interval } from "luxon";
import SelectDate from "../components/Data/SelectDate";
import BarStatiscticsAmount from "../components/Charts/BarStatisticsAmount";
import ScatterStatisticsTimeline from "../components/Charts/ScatterStatisticsTimeline";

export default function Statistics() {
    const theme = useTheme();
    const [interval, setInterval] = useState<Interval>(
        Interval.fromDateTimes(DateTime.now().startOf("day"), DateTime.now().endOf("day"))
    );
    const [intervalName, setIntervalName] = useState<IntervalName>("Сегодня");

    return (
        <ThemeProvider theme={theme}>
            <SelectedIntervalContext.Provider
                value={{
                    interval: interval,
                    intervalName: intervalName,
                    changeInterval: (i) => setInterval(i),
                    changeIntervalName: (iN) => setIntervalName(iN),
                }}
            >
                <Grid container justifyContent="space-between" style={{ marginBottom: 16 }}>
                    <Typography variant="h3" style={{ color: "#5D6570" }}>
                        Статистика
                    </Typography>
                </Grid>
                <SelectDate />
                <BarStatiscticsAmount />
                <ScatterStatisticsTimeline />
            </SelectedIntervalContext.Provider>
        </ThemeProvider>
    );
}
