import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { StatisticsTimelineData } from "../../common/types";
import { SelectedIntervalContext } from "../../context/SelectedIntervalContext";
import instance from "../../services/axiosConfig";
import { CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from "recharts";
import { DateTime } from "luxon";

export default function ScatterStatisticsTimeline() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState<StatisticsTimelineData[]>([]);
    const { interval, intervalName } = useContext(SelectedIntervalContext);

    useEffect(() => {
        if (interval.start === null || interval.end === null) return;
        instance
            .post<StatisticsTimelineData[]>(
                "api/statistics/timeline",
                {
                    start: Math.round(interval.start.toUTC().valueOf() / 1000),
                    end: Math.round(interval.end.toUTC().valueOf() / 1000),
                },
                {
                    headers: {
                        Accept: "*/*",
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                setData(res.data);
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                });
            });
    }, [enqueueSnackbar, interval]);

    const getTickFormater = (time: number) => {
        switch (true) {
            case interval.length() <= 86400000: // 1 day
                return DateTime.fromSeconds(time)
                    .setLocale("ru")
                    .toLocaleString({
                        ...DateTime.TIME_24_WITH_SECONDS,
                        hourCycle: "h23",
                    });
            case interval.length() <= 2419200000: // 28 days
                return DateTime.fromSeconds(time).setLocale("ru").toLocaleString({
                    month: "short",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hourCycle: "h23",
                });
            default:
                return DateTime.fromSeconds(time).setLocale("ru").toLocaleString({
                    month: "short",
                    day: "2-digit",
                    hour: "2-digit",
                    hourCycle: "h23",
                });
        }
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <ScatterChart>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    type="number"
                    dataKey="time"
                    domain={([_, dataMax]) => {
                        if (interval.start === null || interval.end === null) return [0, dataMax];
                        return [
                            Math.round(interval.start.toUTC().valueOf() / 1000),
                            Math.round(interval.end.toUTC().valueOf() / 1000),
                        ];
                    }}
                    minTickGap={10}
                    tickFormatter={(time) => {
                        return getTickFormater(time);
                    }}
                />
                <YAxis type="category" dataKey="name" width={100} />
                <Tooltip
                    formatter={(value) => {
                        if (typeof value === "string") {
                            return value;
                        }
                        return DateTime.fromSeconds(Number(value))
                            .setLocale("ru")
                            .toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, hourCycle: "h23" });
                    }}
                />
                <Legend />
                <Scatter data={data} fill="#007FFF" />
            </ScatterChart>
        </ResponsiveContainer>
    );
}
