import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Transcribation from "../pages/Transcribation";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import SearchCompany from "../pages/SearchCompany";
import Questions from "../pages/Questions";
import History from "../pages/History";
import Group from "../pages/Group";
import Calling from "../pages/Calling";
import AuthRoute from "./AuthRoute";
import NotFound from "../pages/NotFound";
import { useAuth } from "../context/AuthProvider";
import Forbidden from "../pages/Forbidden";
import ContactSupport from "../pages/ContactSupport";
import Conclusion from "../pages/Conclusion";
import Statistics from "../pages/Statistics";

export default function RouterMain() {
    const { token } = useAuth();

    return (
        <BrowserRouter>
            <Routes>
                <Route path="sign-in" element={<SignIn />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route element={<AuthRoute isNotAuth={token === ""} />}>
                    <Route path="*" element={<NotFound />} />
                    <Route path="transcribation" element={<Transcribation />} />
                    <Route path="calling" element={<Calling />} />
                    <Route path="video-interview" element={<Forbidden />} />
                    <Route path="search-company" element={<SearchCompany />} />
                    <Route path="search-language" element={<Forbidden />} />
                    <Route path="questions" element={<Questions />} />
                    <Route path="conclusion" element={<Conclusion />} />
                    <Route path="user-guide" element={<NotFound />} />
                    <Route path="support" element={<ContactSupport />} />
                    <Route path="statistics" element={<Statistics />} />
                    <Route path="group" element={<Group />} />
                    <Route path="history" element={<History />} />
                    <Route path="sign-in" element={<NotFound />} />
                    <Route path="sign-up" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
