import { DateTime, Interval } from "luxon";
import { createContext } from "react";

export type IntervalName = "Сегодня" | "Вчера" | "За неделю" | "За месяц" | "За год" | "Выбрать";
export const intervalNames: IntervalName[] = ["Сегодня", "Вчера", "За неделю", "За месяц", "За год", "Выбрать"];

interface IntervalContext {
    interval: Interval;
    intervalName: IntervalName;
    changeInterval?: (newInteraval: Interval) => void;
    changeIntervalName?: (newInteravalName: IntervalName) => void;
}

export const SelectedIntervalContext = createContext<IntervalContext>({
    interval: Interval.fromDateTimes(DateTime.now().startOf("day"), DateTime.now().endOf("day")),
    intervalName: "Сегодня",
});
